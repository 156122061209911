.mainContainer {
  /* margin: 0px 100px; */
  /* background-color: yellow; */
}
.HomePageHeaderText {
  padding: 10px 0px;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  /* background-color: rebeccapurple; */
}
.logo_login {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  background-color: yellowgreen;
  border-radius: 6px;
  color: white;
}
.companyLogo {
  /* padding: 0px 30px; */
  /* background-color: yellow; */
  /* margin-right: 10px; */
}
.nav-link {
  cursor: pointer;
}
.login-mainContainer {
  /* background-color: black; */
  /* height: 500px; */
  display: flex;
}
.fluidContainer {
  /* background-color: black; */
  /* height: 500px; */
}
.loginPageCompanyLogo {
  display: flex;
  justify-content: center;
}
.sidebar_row {
  height: 100vh;
}

@media (min-width: 576px) {
  .h-sm-100 {
    height: 50%;
  }
}
@media (max-width: 579px) {
  .sidebar_row {
    height: auto !important;
  }
}

.rowTableCell {
  font-size: 16px !important;
  text-align: center !important;
}
/* Login page start */
.loginHeader {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  padding: 24px;
}
.loginCompanyLogoContainer {
  width: 100%;
  height: calc(100% - 84px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginFieldMainContainer {
  max-width: 800px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.loginInputContainer {
  height: calc(100% - 284px);
  width: 100%;
  display: flex;
  justify-content: center;
}
.loginFormField {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: solid 1px black; */
  border-radius: 6px;
  padding: 20px 30px;
}
.loginLabel {
  font-size: 20px;
  font-weight: 500px;
  padding: 10px 10px;
}
.loginPageCss {
  width: 280px;
  height: 50px;
  border-radius: 6px;
  border: none;
  font-size: 16px;
  padding-left: 10px;
  background-color: lightGray;
}
.loginBtn {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  border-radius: 6px;
  border: none;
  background-color: yellowGreen;
  color: white; 
  padding: 8px 16px; 
  font-size: 16px 

}
/* Login page end  */

.addUserDatePicker {
  height: 45px;
  border: solid 2px #524f4f;
  border-radius: 6px;
  margin-left: 10px;
}
.addUserPasswordIcon{
  padding-left: 20px;
  /* padding-top: 10px; */
}

/* In put Field css Start */
.inputFiledCss {
  height: 45px;
  border: solid 2px #524f4f;
  border-radius: 6px;
  margin-left: 10px;
  /* text-transform: capitalize; */
  width: 190px;
  outline:none
}

/* Input Field css end */

/* view Employee css Start */
.viewEmployeeCss {
  border: solid 2px gray;
  border-radius: 6px;
  height: 60px;
  padding: 10px;
  width: 350px;
  background-color: lightgray;
}

/* view Employee css end */
.add_user_form_header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  align-items: center;
  gap: 20px;
}
.form_main_head {
  align-items: center;
}
.add_user_form {
  width: 380px;
    display: flex;
    flex-wrap: wrap;
    justify-Content: space-between;
    margin: 20px 0px;
    align-items: center;
  
}
.add_user_password_form{
  width: 450px;
  display: flex;
  /* flex-wrap: wrap; */
  justify-Content: space-between;
  margin: 20px 0px;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .add_user_form_header {
    gap: 20px !important;
  }
  .viewEmployeeCss {
  width: 219.6px;
  }
  .viewEmployeeCss {
    width: 219.6px;
  }
  .react-datepicker-wrapper{
    width: 100% !important;
  }
  /* .--model_file{
    width:100vh !important;
  } */
  .add_user_form{
    flex-direction: column;
    justify-content: end;
    align-items: start;
    gap: 5px;
  }

  .--changedNav_table{
   padding-top: 5rem;
  }
  .form_main_head {
    align-items: normal !important;
    padding-top: 5rem;
  }
  .edit_user_profile{
    width: auto !important;
  }
}


.mobile_navbar {
  display: none;
}
@media screen and (max-width:1200px) {
  .mobile_navbar{
      display: block !important;
  }
  .webNavbar {
    display: none !important;
    
  }
}

.viewEmployeeLabel{

  font-size:18px;
  font-weight:800;
  margin-right:8px;
  padding: 20px 20px 10px 10px;
}


/* NAV NAR */
.--changedNav {
  background-color: #adadad;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 99;
  width: 100%;
  
}

.nav-menu {
  list-style: none;
  padding: 0;
  
}

.nav-menu li {
  padding: 8px;
  margin: 5px;
  background-color: #adadad;
  /* background-color: #fff; */
}

.dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
  /* background-color: #555; */
}

.dropdown li {
  padding: 8px;
  margin: 5px;
}

.menu-toggle {
  display: block;
  cursor: pointer;
}
.nav_item_list {
  color: #000;
  text-decoration: none;
}

.billing_print_btn {
  padding: 4px 10px;
  border-radius: 6px;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: 500;
  color: white;
  background-color: #06ae4b;
  margin: 10px;
}

.view_employee {
  justify-content: center;
  align-items: center;
}
.webNavbar{
  background-color: #286eac;
}
.side_barlist{
  color: #Fff;
  font-size: 18px;
  font-weight: 500;
}
.side_menu_list_items{
  height: 100%;
  justify-content: space-between;
}
.side_barhead{
  padding: 5px 10px;
  margin: 5px 0px;
}
.side_barhead:hover{
  background-color: rgba(255, 255, 255, .4);
  width: 100%;
  border-radius: 9px;
}
.side_barhead:hover .side_barlist{
color: #000;
}
.active{
  background-color: rgba(255, 255, 255, .4);
  width: 100%;
  border-radius: 9px;
}
.active .side_barlist{
  color: #000;
  }
  .logout_btn{
    color: #fff;
    font-size: 18px ;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    cursor: pointer;
  }
  .logout_btn:hover{
    color: #fff;
    background-color: rgba(234, 56, 56, 0.703);
    font-size: 18px ;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    cursor: pointer;
  }

 .edit_user_profile{
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  border: 1px solid;
  padding: 10px;
  justify-content: space-between;
  border-radius: 9px;
 } 
 .abs{
  justify-content: center;
  align-items: center;
 }
 /* Loading Start */
 
.wrapper .product_table_container_sticky {
  overflow:hidden ;
  }
  
  .wrapper{
    width:200px;
    height:60px;
    position: absolute;
    /* left:0%;
    top:0%; */
    transform: translate(50%, 50%);
  }
  .circle{
    width:20px;
    height:20px;
    position: absolute;
    border-radius: 50%;
    background-color: #adadad7c;
    left:15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
  }
  
  @keyframes circle{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
  }
  .circle:nth-child(2){
    left:45%;
    animation-delay: .2s;
  }
  .circle:nth-child(3){
    left:auto;
    right:15%;
    animation-delay: .3s;
  }
  .shadow{
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
  }
  
  @keyframes shadow{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
  }
  .shadow:nth-child(4){
    left: 45%;
    animation-delay: .2s
  }
  .shadow:nth-child(5){
    left:auto;
    right:15%;
    animation-delay: .3s;
  }
  .wrapper span{
    position: absolute;
    top:75px;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 12px;
    color: #000;
    left:15%;
  }
 /* Loading End */
 .tableHead {
  background-color: #286eac !important;
}
 .headerTableCell {
  background-color: #286eac !important;
  padding: 0px !important;
}
.headerTableCell {
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Courier New", Courier, monospace !important;
  /* width: 20px !important; */
}
.--updatepayslippage{
  border-radius: 6px;
     height: 60px;
    width:350px;
    padding: 10px;
    background-color: "lightgray",
}
.employeeDetailsText{
  font-size: 14px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 500;
  width: 190px;
}